import React from 'react'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Item from './Item';
import uuid from 'react-uuid'
import { useState, useEffect } from 'react';

export default function Items(props) {

    let datos = props.data;
    let item = []




  return (
    <div>
        <Row xs={1} md={4} lg={4} className="g-4">
        {datos.map((item) => (
            <Item key={uuid()} data = {item._id} />
        ))}
        <Col xs={1} md={3}>
        </Col>
      
        </Row>



    </div>
  )
}
