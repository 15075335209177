import React, {useEffect, useState} from 'react';
import '../App.css';
import Items from '../components/Items';
import { Loading } from '../components/Loading';


export default function Home() {

    var axios = require('axios');
    const [datos, setDatos] = useState([]);
    const [loading, setLoading] = useState(false);

    

const getData = async () => { 
        
    
    setLoading(true);

    
                
    var config = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/view',
        headers: {
            'Content-Type': 'application/json'
        }
        
    };
              
  axios(config)
      .then(function (response) {
          setDatos(response.data)
          setLoading(false);
      })
      .catch(function (error) {
          console.log(error);
      });

};


useEffect(() => {
    getData()
},[] )



  return (
    <>
    <div className="hero">
    <h1 className="text-center light">Plataforma de manejo de inventario de Emer</h1>
    </div>
    {(() => {
        if (loading === true ) {
          return (
            
            <Loading/>
          ) 
        } 
        if (loading === false ) {
            return (
              <Items data={datos} />

            ) 
        } 
})()}

    
    

    </>
  )
}
