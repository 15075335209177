import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Stack from 'react-bootstrap/Stack';
import uuid from 'react-uuid'
import { useState, useEffect } from 'react';
import { useNavigate} from "react-router-dom";
import { useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';



export default function Additem() {

   // states de la alerta
   const [mensaje, setMensaje] = useState("")
   const [alerta, setAlerta] = useState(false);
   const [colorAlerta, setColorAlerta] = useState("success");
 
   var datos = JSON.stringify(useParams().id);
     var axios = require('axios');
 
 
     const navigate = useNavigate();
     const handleClick = (path) => {
         navigate("/");
     }
     const [show, setShow] = useState(false)
     const[brand, setBrand]= useState("")
     const [nombre, setNombre]= useState("")
     const [ubicacion, setUbicacion]= useState([])
     const [description, setDescription]= useState("")
     const [quantity, setQuantity]= useState("")
     const [image, setImage]= useState("")

     const [listo, setListo]= useState(false)
 

     const handleClose = () => {
    
      setShow(false)
    
    };
    
    const handleCrear = () => { 
  
      setShow(true)
      
      if(brand !== "" && nombre !== "" && ubicacion !== "" && description !== "" && quantity !== 0 && image !== "") {

        setMensaje('nombre: ' +  nombre + " descripción: " + description + " image: " + image + " quantity: " + quantity + " brand: " + brand + " ubicación: " + ubicacion)
        setListo(true)

      }else{
        setMensaje("Ingresa todos los datos correctamente")
      }
      
  
  
    };

    const crearItem = () => {

      var data = JSON.stringify({
        "name": nombre,
        "quantity": quantity,
        "description": description,
        "brand": brand,
        "location": ubicacion.split(","),
        "image": image,
      });
      
      var config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + '/create',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        handleClick()
      })
      .catch(function (error) {
        console.log(error);
      });

    }
  

  return (
    <div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirma Crear el item</Modal.Title>
        </Modal.Header>
        <Modal.Body>{mensaje}</Modal.Body>
        <Modal.Footer>
        {(() => {
        if (listo === true ) {
          return (
            
            <>
            <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => crearItem()}>
            Crear
          </Button>
            </>
          ) 
        } 
        if (listo === false ) {
            return (
              <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>

            ) 
        } 
})()}
          
        </Modal.Footer>
      </Modal>
      <Collapse in={true}>
      <Alert  variant={colorAlerta}>
    {mensaje}
    </Alert>
    </Collapse>
      <Row xs={1} md={1} lg={1}>
      <Col>
<Card>
            <Card.Body>
              <Card.Title>Nombre: 
              <TextField
          id="filled-required"
          value={nombre}
          type="text"
          color="primary"
          variant="filled"
          onChange={(event) => {setNombre(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        /></Card.Title>
              <Card.Text>
                Descripción: <TextField
          id="filled-required"
          value={description}
          type="text"
          color="primary"
          variant="filled"
          onChange={(event) => {setDescription(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        />
              </Card.Text>
              <Card.Text>
                Marca: <TextField
          id="filled-required"
          value={brand}
          type="text"
          color="primary"
          variant="filled"
          onChange={(event) => {setBrand(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        />
              </Card.Text>
              <Card.Text>
                Link de la imágen: <TextField
          id="filled-required"
          value={image}
          type="text"
          color="primary"
          variant="filled"
          onChange={(event) => {setImage(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        />
              </Card.Text>
              <Card.Text>
                Cantidad Disponible: <TextField
          id="filled-required"
          value={quantity}
          type="number"
          color="primary"
          variant="filled"
          onChange={(event) => {setQuantity(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        />
              </Card.Text>
              <Card.Text>
                Ubicación:
                <TextField
                id="filled-required"
                value={ubicacion}
                type="text"
                color="primary"
                variant="filled"
                onChange={(event) => {setUbicacion(event.target.value)}}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              

                {/* {ubicacion.map((item) => (
                <TextField
                key={uuid()}
                id="filled-required"
                value={item}
                type="text"
                color="primary"
                variant="filled"
                onChange={(event) => {setUbicacion(event.target.value)}}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              ))}  */}

 
                
              </Card.Text>
              <Card.Text>Separen la ubicación con comas, ya estoy trabajando en una mejor solución</Card.Text>
              
    <Stack direction="vertical" gap={2}>
      
              <Button className="d-grid gap-2" variant="primary" onClick={() => handleClick()}>Cancelar</Button>
              <Button className="d-grid gap-2" variant="primary" onClick={() => handleCrear()}>Guardar</Button>
     </Stack>
            </Card.Body>
          </Card>
          </Col>
          </Row>
    </div>
  )
}
