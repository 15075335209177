import React from 'react'
import Spinner from 'react-bootstrap/Spinner';


export function Loading() {



  return (
  
    <Spinner animation="border" variant="light" ole="status">
      <span className="visually-hidden" >Cargando...</span>
    </Spinner>
    
  )
}
