import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Stack from 'react-bootstrap/Stack';
import uuid from 'react-uuid'
import { useState, useEffect } from 'react';
import { useNavigate} from "react-router-dom";
import { useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';

export default function Edit(props) {


  // states de la alerta
  const [mensaje, setMensaje] = useState("")
  const [alerta, setAlerta] = useState(false);
  const [colorAlerta, setColorAlerta] = useState("success");

  var datos = JSON.stringify(useParams().id);
    var axios = require('axios');


    const navigate = useNavigate();
    const handleClick = (path) => {
        navigate("/");
    }
    const [show, setShow] = useState(false)

    const[brand, setBrand]= useState("")
    const [nombre, setNombre]= useState("")
    const [fechas, setFechas]= useState([{
      "date": "Disponible",
      "quantity": 1,
      "by": "La Cara de Emer"
    }])
    const [ubicacion, setUbicacion]= useState([])
    const [description, setDescription]= useState("")
    const [quantity, setQuantity]= useState("")
    const [image, setImage]= useState("")
    const [elitem, setElItem] = useState([{
      "date": "Disponible",
      "quantity": 1,
      "by": "La Cara de Emer"
    }])


    const getData = async () => { 
               
      var config = {
          method: 'get',
          url: process.env.REACT_APP_BACKEND_URL + '/find/' + datos.replaceAll('"', ''),
          headers: {
              'Content-Type': 'application/json'
          }
          
      };
                
    axios(config)
        .then(function (response) {
            let datos= response.data
            setNombre(datos.name)
            setDescription(datos.description)
            setImage(datos.image)
            setQuantity(datos.quantity) 
            setFechas(datos.rent)
            setUbicacion(datos.location)
            setBrand(datos.brand)

            if (fechas <= 0) {
              setFechas([{
                "date": "Disponible",
                "quantity": 1,
                "by": "La Cara de Emer"
              }])
            }
  
        })
        .catch(function (error) {
            console.log(error);
        });
  
  };

  const borrarFecha = (item) =>{

    setShow(true)
    setElItem(item)

  }

  const handleClose = () => {
    
    setShow(false)
  
  };
  
  const handleBorrar = () => { 

    console.log(fechas.length)

    for(var i = 0; i< fechas.length; i++){
      console.log('llegué')
      if(fechas[i] === elitem){

        fechas.splice(i, 1)

        handleClose()
      }


    }

    var data = JSON.stringify({
      "rent": fechas
    });
    
    var config = {
      method: 'put',
      url: process.env.REACT_APP_BACKEND_URL + '/book/update/' + datos.replaceAll('"', ''),
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });


  };

  const editar = () =>{

    
    
    var data = JSON.stringify({
      "name": nombre,
      "quantity": quantity,
      "description": description,
      "brand": brand,
      "location": ubicacion.split(","),
      "image": image,

    });
    
    var config = {
      method: 'put',
      url: process.env.REACT_APP_BACKEND_URL + '/item/' + datos.replaceAll('"', ''),
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      setMensaje(JSON.stringify(response.data.message))
      setAlerta(true)
      setColorAlerta('success')
    })
    .catch(function (error) {
      console.log(error);
      setMensaje(error)
      setAlerta(true)
      setColorAlerta('danger')
    });
    

  }
  
  

    useEffect(() => {
      getData()
  },[] )

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirma Borrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>Confirma que quieres borrar la reserva {elitem.date}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => handleBorrar()}>
            Borrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Collapse in={true}>
      <Alert  variant={colorAlerta}>
    {mensaje}
    </Alert>
    </Collapse>
      <Row xs={1} md={1} lg={1}>
      <Col>
<Card>
            <Card.Body>
              <Card.Title>Nombre:
              <TextField
          id="filled-required"
          value={nombre}
          type="text"
          color="primary"
          variant="filled"
          onChange={(event) => {setNombre(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        /></Card.Title>
              <Card.Text>
                Descripción: <TextField
          id="filled-required"
          value={description}
          type="text"
          color="primary"
          variant="filled"
          onChange={(event) => {setDescription(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        />
              </Card.Text>
              <Card.Text>
                Marca: <TextField
          id="filled-required"
          value={brand}
          type="text"
          color="primary"
          variant="filled"
          onChange={(event) => {setBrand(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        />
              </Card.Text>
              <Card.Text>
                Cantidad Disponible: <TextField
          id="filled-required"
          value={quantity}
          type="number"
          color="primary"
          variant="filled"
          onChange={(event) => {setQuantity(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        />
              </Card.Text>
              <Card.Text>
                Ubicación:
                <TextField
                id="filled-required"
                value={ubicacion}
                type="text"
                color="primary"
                variant="filled"
                onChange={(event) => {setUbicacion(event.target.value)}}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              

                {/* {ubicacion.map((item) => (
                <TextField
                key={uuid()}
                id="filled-required"
                value={item}
                type="text"
                color="primary"
                variant="filled"
                onChange={(event) => {setUbicacion(event.target.value)}}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              ))}  */}

 
                
              </Card.Text>
              <Card.Text>Separen la ubicación con comas, ya estoy trabajando en una mejor solución</Card.Text>
              <Accordion>
      <Accordion.Item eventKey="0" className="fechas-accordion">
        <Accordion.Header>Ver fechas rentadas</Accordion.Header>
        <Accordion.Body>
        <ButtonGroup aria-label="Basic example">
        <div className="fechas">
          {fechas.map((item) => (
            <>
            
                <Button key={uuid()} variant= "secondary" size="sm" onClick={() => borrarFecha(item)}>{item.date}, {item.quantity}, por: {item.by} Borrar</Button>
                
            </>
          ))}
              </div>
        </ButtonGroup>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <Stack direction="vertical" gap={2}>
              <Button className="d-grid gap-2" variant="primary" onClick={() => handleClick()}>Cancelar</Button>
              <Button className="d-grid gap-2" variant="primary" onClick={() => editar()}>Guardar</Button>
     </Stack>
            </Card.Body>
          </Card>
          </Col>
          </Row>
    </div>
  )
}
