import './App.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Header from './components/Header';
import Button from 'react-bootstrap/Button';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { useContext, useState, useEffect} from 'react';


// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Import pages
import Home from './pages/Home';
import Additem from './pages/Additem';
import Book from './pages/Book';
import Edit from './pages/Edit';
import Login from './pages/Login';
import { useAuth0 } from '@auth0/auth0-react'

function App() {


  const {user, isAuthenticated} = useAuth0();

  return (
    <div className="App">
      <BrowserRouter>
      <header className="App-header">
        <Header isAuthenticated={isAuthenticated} user={user}/>
      </header>
      <main>
      <Routes>
      {(() => {
        if (isAuthenticated ) {
          return (
            <>
            <Route path="/" element={<Home/>} />
            <Route path="/add" element={<Additem/>} />
            <Route path="/edit/:id" element={<Edit/>} />
            <Route path="/book/:id" element={<Book/>} />
            </>
          ) 
        } else {

        <Route path="/" element={<Login/>} />
        }
})()}
      <Route path="/" element={<Login/>} />
      <Route path="*" element={<Login/>} />
      </Routes>
      </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
