import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Outlet, Link } from "react-router-dom"
import Logout from './Logout';

export default function Header(props) {


  const isAuthenticated = props.isAuthenticated;
  const user = props.user;


  return (
    <>
     {(() => {
        if (isAuthenticated ) {
          return (
            <>
 <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Container>
    <Navbar.Brand as={Link} to="/">
        <img
          alt=""
          src="/logo.png"
          width="200"
          height="110"
          className="d-inline-block align-center"
        />
        Equipos
      </Navbar.Brand>
     

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link as={Link} to="/add">Crear Equipo</Nav.Link>
      </Nav>
    </Navbar.Collapse>
    <Navbar.Text>
        Logeado como: {user.name}
        <Logout/>
      </Navbar.Text>
    </Container>
  </Navbar>
            </>
          ) 
        } else {

          
        }
})()}
{(() => {
        if (!isAuthenticated ) {
          return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
          <Navbar.Brand as={Link} to="/">
              <img
                alt=""
                src="/logo.png"
                width="200"
                height="110"
                className="d-inline-block align-center"
              />
              Sistema de gestión de equipos rental La Cara de Emer | Vyuu.Tech
            </Navbar.Brand>
          </Container>
        </Navbar>
          ) 
        }
})()}
   
  </>
  )
}