import React from 'react'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import Alert from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';
import Spinner from 'react-bootstrap/Spinner';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal';
import uuid from 'react-uuid';



export default function Book() {

  //Const modal
  const [show, setShow] = useState(false)

    var datos = JSON.stringify(useParams().id);
    const [value, onChange] = useState(new Date());
    const [cantidad, setCantidad] = useState('');
    const [por, setPor] = useState('');
    const [mensaje, setMensaje] = useState("Ingresa la info para reservar")
    const [alerta, setAlerta] = useState(false);
    const [colorAlerta, setColorAlerta] = useState("success");
    const [creando, setCreando] = useState(false)
    const locale = 'es-CO'; 
    const[brand, setBrand]= useState("")


    var axios = require('axios');

    const [nombre, setNombre]= useState("")
    const [fechas, setFechas]= useState([{
      "date": "Disponible",
      "quantity": 1,
      "by": "La Cara de Emer"
    }])
    const [ubicacion, setUbicacion]= useState([])
    const [description, setDescription]= useState("")
    const [quantity, setQuantity]= useState("")
    const [image, setImage]= useState("")

    // reserva a borrar
    const [elitem, setElItem] = useState([{
      "date": "Disponible",
      "quantity": 1,
      "by": "La Cara de Emer"
    }])


    const getData = async () => { 
               
        var config = {
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + '/find/' + datos.replaceAll('"', ''),
            headers: {
                'Content-Type': 'application/json'
            }
            
        };
                  
      axios(config)
          .then(function (response) {
              let datos= response.data
              setNombre(datos.name)
              setDescription(datos.description)
              setImage(datos.image)
              setQuantity(datos.quantity) 
              setFechas(datos.rent)
              setUbicacion(datos.location)
  
              if (fechas <= 0) {
                setFechas([{
                  "date": "Disponible",
                  "quantity": 1,
                  "by": "La Cara de Emer"
                }])
              }
    
          })
          .catch(function (error) {
              console.log(error);
          });
    
    };

    const bookear = () =>{

 
        if(value && cantidad && por){

        

        setCreando(true);

        var axios = require('axios');
        var data = JSON.stringify({
        "rent": [
            {
            "date": value.toLocaleDateString(),
            "quantity": parseInt(cantidad),
            "by": por
            }
        ]
        });

        var config = {
        method: 'put',
        url: process.env.REACT_APP_BACKEND_URL + '/book/' + datos.replaceAll('"', ''),
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios(config)
        .then(function (response) {
            if (response.statusCode === 200) {
                
                setMensaje(JSON.stringify(response.data.message))
                setAlerta(true)
                setColorAlerta('success')
                setCreando(false)
            }else{
                setColorAlerta('danger')
                setMensaje(JSON.stringify(response.data.message))
                setAlerta(true)
                setCreando(false)
            }
        setCreando(false)
        getData()
        })
        .catch(function (error) {
        console.log(error);
        });

    }else{
        setColorAlerta('danger')
        setMensaje("Ingresar todos los datos")
        setAlerta(true)
    }
    }
    
  
    useEffect(() => {

      getData()

    },[] )


    //func borrar reserva 

    const borrarFecha = (item) =>{

      setShow(true)
      setElItem(item)
  
    }
  
    const handleClose = () => {
      
      setShow(false)
    
    };
    
    const handleBorrar = () => { 

      console.log(fechas.length)
  
      for(var i = 0; i< fechas.length; i++){
        console.log('llegué')
        if(fechas[i] === elitem){
  
          fechas.splice(i, 1)
  
          handleClose()
        }
  
  
      }
  
      var data = JSON.stringify({
        "rent": fechas
      });
      
      var config = {
        method: 'put',
        url: process.env.REACT_APP_BACKEND_URL + '/book/update/' + datos.replaceAll('"', ''),
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  
  
    };


  return (
    <>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirma Borrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>Confirma que quieres borrar la reserva {elitem.date}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => handleBorrar()}>
            Borrar
          </Button>
        </Modal.Footer>
      </Modal>
    <div className="book">
    <Collapse in={alerta}>
    <Alert  variant={colorAlerta}>
    {mensaje}
    </Alert>
    </Collapse>
    <Collapse in={creando}>
    <Spinner animation="border" variant="light" ole="status">
      <span className="visually-hidden" >Cargando...</span>
    </Spinner>
    </Collapse>
    <h1>Reserva de equipos de Emer</h1>
    <h2>Reserva para {nombre}</h2>
    <div className="fechas">
        <p> Fechas ya reservadas </p>
          {fechas.map((item) => (
            <>
            
                <Button key={uuid()} variant= "secondary" size="sm" onClick={() => borrarFecha(item)}>{item.date}, {item.quantity}, por: {item.by}</Button>
                
                </>
          ))}
              </div>
<p>Da click en una reserva para borrarla</p>
    <Form>
      <Row xs={1} md={4} lg={4}>
        <Col>
        <Calendar onChange={onChange} value={value} />
        </Col>
        <Col>
        <TextField
          id="filled-required"
          label="Cantidad"
          type="number"
          color="secondary"
          variant="filled"
          onChange={(event) => {setCantidad(event.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Col>
        <Col>
        <TextField onChange={(event) => {setPor(event.target.value)}} color="secondary" id="filled-required" label="Reservado por" variant="filled" />
        </Col>
        <Col>
        <Button variant="primary" onClick={bookear}>
        Reservar
        </Button>
        </Col>
      </Row>
    </Form>
    </div>
    </>
  )
}
