import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Stack from 'react-bootstrap/Stack';
import uuid from 'react-uuid'
import { useState, useEffect } from 'react';
import { useNavigate} from "react-router-dom";

export default function Item(props) {

    var data = props.data.$oid;
    var axios = require('axios');


    const navigate = useNavigate();
    const handleClick = (path) => {
        navigate(path + '/' + data);
    }
  

    const [nombre, setNombre]= useState("")
    const [fechas, setFechas]= useState([{
      "date": "Disponible",
      "quantity": 1,
      "by": "La Cara de Emer"
    }])
    const [ubicacion, setUbicacion]= useState([])
    const [description, setDescription]= useState("")
    const [quantity, setQuantity]= useState("")
    const [image, setImage]= useState("")
    

    const getData = async () => { 
               
      var config = {
          method: 'get',
          url: process.env.REACT_APP_BACKEND_URL + '/find/' + data,
          headers: {
              'Content-Type': 'application/json'
          }
          
      };
                
    axios(config)
        .then(function (response) {
            let datos= response.data
            setNombre(datos.name)
            setDescription(datos.description)
            setImage(datos.image)
            setQuantity(datos.quantity) 
            setFechas(datos.rent)
            setUbicacion(datos.location)

            if (fechas <= 0) {
              setFechas([{
                "date": "Disponible",
                "quantity": 1,
                "by": "La Cara de Emer"
              }])
            }
  
        })
        .catch(function (error) {
            console.log(error);
        });
  
  };
  

    useEffect(() => {
      getData()
  },[] )
  
  return (
    <div>
        
          <Card>
            <Card.Img variant="top" src={image} />
            <Card.Body>
              <Card.Title>{nombre}</Card.Title>
              <Card.Text>
                {description}
              </Card.Text>
              <Card.Text>
                Disponible: {quantity}
              </Card.Text>
              <Card.Text>
                Ubicación: 
                {ubicacion.map((item) => (
                <Button key={uuid()} variant= "secondary" size="sm" disabled>{item}</Button>
              ))}
              </Card.Text>
              <Accordion>
      <Accordion.Item eventKey="0" className="fechas-accordion">
        <Accordion.Header>Ver fechas rentadas</Accordion.Header>
        <Accordion.Body>
        <ButtonGroup aria-label="Basic example">
        <div className="fechas">
          {fechas.map((item) => (
            <>
            
                <Button key={uuid()} variant= "secondary" size="sm" disabled>{item.date}, {item.quantity}, por: {item.by}</Button>
                
                </>
          ))}
              </div>
        </ButtonGroup>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <Stack direction="vertical" gap={2} ms-auto>
              <Button className="d-grid gap-2" variant="primary" onClick={() => handleClick("book")}>Reservar</Button>
              <Button className="d-grid gap-2" variant="primary" onClick={() => handleClick("edit")}>Editar</Button>
     </Stack>
            </Card.Body>
          </Card>

    </div>
  )
}
